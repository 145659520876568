#selfie {
    height: 200px;
    width: 200px;
}

.project-image {
    height: 200px;
    width: 200px;
}

.testimonial {
    font-style: italic;
    font-size: 24px;
}